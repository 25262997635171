@use "sass:color";

.featured_b_l {
  .cont {
    background: $color_neutral_soft;
    padding: 2em 3em;
  }

  .highlight {
    margin: 0.5em  0;
  }

  .ic {
    margin: 0 0 0 1em;
    box-sizing: border-box;
    font-size: 1.25em;
    font-weight: 200;

    label {
      display: table-cell;
      vertical-align: bottom;
      line-height: 1em;
      height: 40px;
    }
  }

  & > :first-child {
    margin: 0;
  }

  .title {
    box-sizing: border-box;
    font-size: 1.6em;
    line-height: 1.4em;
    font-weight: 200;
    text-align: right;
    padding-right: 2em;
    padding-top: 0.5em;
    border-right: 1px solid $color_separator;

    strong {
      font-weight: 400;
      display: block;
    }
  }

  small {
    line-height: 0.5em;
  }

  @include screen(740) {
    .cont {
      padding: 2em 1em;
      text-align: center;
    }

    .title {
      padding: 0;
      border: 0;
      text-align: center;
      margin: 0 0 1em;
      font-size: 1.1em;
    }

    .ic {
      margin: 0 0 0.5em;

      label {
        height: initial;
        display: block;
      }
    }

    .highlight {
      margin: 0.25em 0;
    }
  }
}

.explore_detail {
  h3 {
    font-weight: 200;
    margin-top: 4.85em;
  }

  @include reset_ul_li;

  li {
    display: block;
  }

  input[type="text"] {
    width: 70%;
  }

  button {
    width: 23%;
  }

  table.med_bg {
    .fas {
      margin-right: 0.5em;
    }

    td,
    th {
      padding: 0.7em 1em 0.4em;
    }

    td.qty,
    td.bar {
      padding: 0.7em 0 0.4em;
    }

    td.bar {
      padding-left: 10px;
    }

    td {
      table {
        font-size: 1rem;
        border: 1px solid rgba(var(--color-base-string), 0.15);
      }

      .child_tb_cont {
        padding: 1em 1.5em;
      }
    }
  }

  @include screen(740) {
    [data-budget-lines] {
      padding: 0;
    }
  }
}

/*
 * Budget Lines
 *
 */
.budget_line {
  header {
    h1,
    .social_links_container {
      margin-top: 0;
      display: inline-block;
    }

    .social_links_container {
      float: right;
    }
  }

  .line_description {
    p {
      margin-top: 0;
      font-size: 1em;
      line-height: 1.6em;
    }
  }

  @include screen(740) {
    .line_description {
      p {
        font-size: 0.85em;
        line-height: 1.3em;
      }
    }
  }

  .metric_boxes {
    margin-bottom: 2em;
  }

  .block {
    h2 {
      margin-bottom: 1em;
    }
  }
}

.show_me_another {
  text-align: center;
  margin: 1em 0;
  font-size: 0.85em;

  a {
    text-decoration: none;
    text-transform: uppercase;
    color: rgba(var(--color-base-string), 0.75);
    display: inline-block;
    padding: 0.5em 2em 0.25em;

    @include border-radius(4px);
  }

  .fas {
    margin: 0 0.5em 0 0;
    font-size: 0.85em;
  }

  a:hover {
    background: rgba(var(--color-base-string), 0.1);
    color: var(--color-base);
  }
}

/*
 * Execution
 *
 */
.execution {
  margin: 3em 0;

  .box {
    .inner {
      box-sizing: border-box;
    }

    .vizz {
      /* border: 1px solid #DFDFDF; */
      min-height: 60px;
      margin: 1em 0 2em 2em;

      .axis {
        line,
        path {
          fill: none;
          stroke: #d6dddf;
          shape-rendering: crispedges;
        }

        .tick {
          text {
            fill: #b0b2ae;
            font-size: 11px;
          }
        }
      }

      .line {
        fill: none;
        stroke: #b0b2ae;
        stroke-width: 1px;
        stroke-dasharray: 5, 2;
      }

      line.year_marker {
        fill: none;
        stroke: #009baa;
        stroke-width: 1.5px;
      }

      .y.axis path,
      .x.axis path {
        display: none;
      }
    }

    h3 {
      margin: 0.67em 0;
      color: var(--color-base);
      font-size: 3.6em;
    }

    .totals {
      table {
        width: 100%;
        margin-bottom: 1em;
      }

      th,
      td {
        border: 0;
        white-space: nowrap;
        font-size: 1em;
        width: 50%;
      }

      th {
        font-weight: bold;
        text-align: right;
      }

      td {
        text-align: left;
        margin-left: 1em;
      }

      p {
        margin: 0.25em 0;
      }
    }
  }

  h2.lite {
    display: inline-block;
    min-height: 3em;
    width: 50%;
  }

  .form_filters {
    margin: 0;
  }

  table {
    width: 100%;

    td,
    th {
      border: 0;
      padding: 0.35em 1em 0.1em;
    }

    th {
      font-size: 0.7em;
      font-weight: bold;
      background: none;
      text-transform: none;
      text-align: right;
    }

    td {
      text-align: right;
      white-space: nowrap;
    }

    td.title {
      text-align: left;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0;
    }
  }

  @include screen(1280) {
    table td.title {
      max-width: 160px;
    }
  }

  @include screen(1024) {
    .deviation_table_container {
      padding-bottom: 2em;
      margin-bottom: 2em;
      border-bottom: solid 1px rgb(178 175 169 / 50%);
    }

    .deviation_table_container:last-child {
      border-bottom: 0;
    }
  }

  .deviation_widget {
    min-height: 275px;
  }
}

/* Budget execution */
.budgets_execution {
  .option {
    margin-bottom: 1rem;
    z-index: 1;
  }

  .metric_boxes {
    .inner {
      margin-top: 4em;
    }
  }

  .block_description {
    @media (width >= 740px) {
      margin-right: 2em;
    }
  }

  .expenses_execution,
  .income_execution {
    position: relative;
    min-height: 500px;

    svg {
      overflow: visible;
    }

    .axis {
      font-family: $font_text;

      .domain {
        display: none;
      }
    }

    .bar-group:hover {
      stroke: black !important;
    }

    .y.axis {
      font-size: 1rem;

      text {
        fill: $color_text;
        font-weight: 600;
      }

      line {
        display: none;
      }
    }

    .x.axis {
      font-size: $f7;

      text {
        fill: rgba($color_text, 0.5);
      }

      line {
        stroke-dasharray: 2 10;
        stroke: rgb(0 0 0 / 50%);
      }

      .hundred_percent {
        line {
          display: none;
        }

        text {
          fill: $color_text;
          font-weight: 700;
        }
      }
    }

    .tooltip {
      pointer-events: none;
      display: none;
      position: absolute;
      background: white;
      padding: 4px 8px;
      z-index: 4;
      min-width: 200px;
      max-width: 380px;
      border: 1px solid #979797;
      box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
      border-radius: 4px;

      .line-pct {
        width: 85px;

        i {
          display: inline;
        }
      }

      @media (width <= 590px) {
        position: fixed;
        inset: auto -1px -1px;
        width: calc(96%);
        width: auto !important;
        max-width: none;
      }
    }

    .legend {
      font-style: italic;
      fill: #a4a4a4;
      font-size: 0.875rem;
    }

    .year_progress {
      font-weight: 600;
      font-style: italic;
      fill: #a4a4a4;
      font-size: 0.875rem;

      line {
        stroke-dasharray: 4 4;
      }
    }

    .tooltip::before {
      border-radius: 2px;
      position: absolute;
      top: -14px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent color.adjust(#979797, $lightness: -20%);
      content: '';
    }

    .tooltip::after {
      border-radius: 2px;
      position: absolute;
      top: -12px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent white;
      content: '';
    }
  }
}

/* Budget guide */
.budgets_guide {
  header.meta {
    margin: 0;
  }

  header.meta .site_header {
    padding: 0;
  }
}

/* Vis lines */
#lines_chart_comparison_wrapper {
  .flex {
    display: flex;
    flex-direction: column;

    @include min-screen(640) {
      flex-direction: row;
    }
  }

  .img-container {
    display: flex;
    justify-content: center;

    @include min-screen(640) {
      img {
        max-width: 75%;
      }
    }
  }

  .compare-text {
    font-size: large;
    margin-right: 1em;

    a {
      text-decoration: none;
    }
  }

  #comparison_widget_wrapper {
    margin-top: 20px;
  }
}
