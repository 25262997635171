//Overlays
.overlay-container {
  position: relative;

  .overlay {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    inset: 0;
    background-color: rgb(0 0 0 / 10%);
    z-index: 2;
    cursor: pointer;

    .overlay-content {
      position: absolute;
      top: 50%;
      margin: 0 auto;
      font-size: $f2;
      color: $color_main_negative;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.is-active {
    overflow: hidden;

    .overlay {
      display: block;
    }

    > div:not(.overlay) {
      filter: blur(5px);
      overflow: hidden;
    }
  }
}
