/*
 * Filter
 *
 */
.filter {
  font-size: 0.75em;

  @include screen(768) {
    &.m_2 {
      margin: 0 0 1em;
    }
  }

  a {
    display: inline-block;
    padding: 0.35em 1.25em 0.25em;
    margin: 0 0.75em 0 0;

    @include border-radius(2em);

    background: rgba(var(--color-base-string), 0.2);
    text-decoration: none;
    cursor: pointer;

    @include screen(768) {
      padding: 0.35em 0.5em 0.25em;
    }
  }

  a:hover {
    background: rgba(var(--color-base-string), 0.3);
    text-decoration: none;
  }

  a.active,
  a.active:hover {
    border: 1px solid rgba(var(--color-base-string), 0.2);
    background: $color_main_negative;
  }

  a.active {
    cursor: default;
  }

  a.help {
    font-weight: bold;
    padding: 0.35em 1em 0.25em;
  }

  .separator_v {
    margin: -0.25em 2em 0;
  }

  span.label {
    padding: 0 1em;
  }

  a:last-child {
    margin-right: 0;
  }
}

/* comparator version, pending to unify */
.form_filters {
  display: inline-block;
  float: right;
  font-size: 12px;
  margin: 1em 0;

  .group {
    display: inline-block;
    margin: 0 0 0 1em;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-block;
  }

  ul {
    @include border-radius(2em);

    background: #eff8f9;
    padding: 0 10px;
    margin: 0;
  }

  li a {
    display: inline-block;
    border-right: 1px solid var(--color-base);
    text-decoration: none;
    padding: 1px 7px;
    margin: 0;
  }

  li:last-child a {
    border: 0;
  }

  li a:hover,
  li a.buttonSelected,
  a.active {
    background: #d1eaed;
  }
}
