@use "sass:color";

// Proposals highlight style
.highlight-proposal {
  background: $color_proposal_bg;
  margin: 1em 0;

  p {
    color: $color_proposal;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.5em;
    font-size: 1em;
    margin: 0;
  }

  span {
    font-size: 0.9em;
    margin: 0 0.5em 0 0.25em;
  }
}

.metric_boxes .metric_box.cta {
  text-align: center;
  padding: 0;

  .inner {
    padding: 0;
    background: #e9ad00;
    min-height: 100px;
    transition: all 0.15s ease-out 0s;
  }

  .inner:hover {
    background: color.adjust(#e9ad00, $lightness: -10%);
  }

  a {
    padding: 1.85em 0.5em;
    color: #fff;
    display: inline-block;
    font-size: 1em;
    text-decoration: none;

    .main {
      text-transform: uppercase;
    }

    .lite {
      font-weight: 300;
      font-size: 0.85rem;
    }
  }

  .fas {
    background: #fff;
    color: $color_logo_yellow;
    padding: 0.75em;

    @include border-radius(3px);

    margin-left: 0.5em;
  }
}

.metric_boxes .metric.cta:hover {
  border: 1px solid red;
}

/*
 * Budget Line Feedback
 *
 */
.ask_your_entity {
  .inner {
    @include min-screen(900) {
      width: 65%;
      margin: auto;
    }
  }
}

.budget_line_feedback {
  background: #f5f4f4;
  text-align: center;
  padding: 3em;

  .options {
    margin: 1.5em 0;

    .button {
      margin: 0 0.2em;

      span {
        display: block;
        text-transform: uppercase;
      }
    }
  }

  .reply {
    max-width: 60em;
    margin: auto;
    padding: 2em;
    background: #fff;

    @include border-radius(6px);

    .inner {
      margin: 0 20%;
    }
  }

  .options.yes_no {
    font-size: 1.5em;

    .button {
      font-weight: bold;
    }
  }

  .form_item {
    text-align: left;

    .form_item_cont {
      label {
        width: 75px;
      }

      .i_text {
        width: 60%;
      }
    }
  }

  .submit_item {
    text-align: left;
    margin-left: 87px;
  }

  .recover_password {
    font-size: 0.8em;
  }

  .button.empty {
    color: $color_text;
    cursor: auto;
  }
}

.small_button {
  background: var(--color-base);

  @include border-radius(3px);

  padding: 0.1em 0.75em 0;
  color: #fff;
  display: inline-block;
  text-decoration: none;
}

.small_button:hover {
  background: rgba(var(--color-base-string), 0.8);
  text-decoration: none;
}

.button.empty {
  background: #fff;
  border: 1px solid $text_soft;

  span {
    display: block;
    text-transform: uppercase;
  }
}

.sep {
  border-top: 1px solid $separator;
  margin: 1em 0;
  height: 1px;
  color: #999;
}

.table-breakdown {
  background: rgba(var(--color-base-string), 0.06);

  .table-breakdown-element {
    color: var(--color-base);
    cursor: pointer;
  }

  tr.show-sublevel {
    .table-breakdown-element {
      font-weight: bold;
    }

    + .container-breakdown-sublevel {
      display: table-row;
    }

    i {
      transform: rotate(90deg);
    }
  }

  i {
    color: var(--color-base);
    margin-right: .5rem;
  }

}

.container-breakdown-sublevel {
  display: none;

  table {
    width: 91%;
    margin-left: 9%;
  }

  .container-breakdown-sublevel-td-element {
    .table-breakdown-element {
      margin-left: 0;
    }

    &.align-right {
      text-align: right;
    }
  }
}

.container-breakdown-sublevel-td {
  padding-left: 0;
  padding-right: 0;
}
