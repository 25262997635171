.timeline {
  position: relative;

  .timeline_row {
    position: relative;
    width: 100%;
    padding: 0.5em;
    margin-bottom: 0.5em;

    @include min-screen(740) {
      margin-bottom: 3em;
    }

    .timeline_separator {
      display: none;

      @include min-screen(740) {
        display: inline-block;
      }
    }
  }

  .badge {
    float: right;
    min-width: 4em;
    margin-top: 0.5em;

    strong {
      padding: 0 0.5em;
    }
  }

  .line {
    margin-top: 1em;
    position: absolute;
    right: 1em;
    width: 12px;
    height: calc(100% - 8em);
    background: $color_main_soft;
    border-radius: 4px;

    @include min-screen(740) {
      left: 20%;
      z-index: 1;
    }
  }

  .current {
    background: #fefbd2;
    border-radius: 4px;
    border: 1px solid $separator;
  }

  /* Active line which fills the timeline */
  // TODO: Fix active bar for the current process
  .timeline_row.active:not(:last-child, :nth-child(2))::before {
    margin-top: 1.25em;
    z-index: 1;
    content: '';
    position: absolute;
    left: 10.25em;
    width: 4px;
    top: calc(-100% - 2em);
    height: calc(100% + 3em);
    background: var(--color-base);
  }

  .content_block {
    position: relative;
    min-height: inherit;

    h3 {
      cursor: pointer;
    }

    .description {
      display: none;
      max-width: 90%;
      margin-bottom: 0.5em;
    }

    .circle {
      @include circle(2em);

      position: absolute;
      top: 1em;
      right: 1em;
      display: none;

      @include min-screen(740) {
        display: block;
      }
    }

    &::before {
      content: '';
      position: absolute;
      background: $color_main_soft;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      top: 0.85em;
      left: -8.275%;

      @include min-screen(1200) {
        left: -7.95%;
      }
    }

    &::after {
      content: '';
      z-index: 2;
      position: absolute;
      background: var(--color-base);
      border-radius: 50%;
      width: 10px;
      height: 10px;
      top: 1.1em;
      left: -7.5%;

      @include min-screen(1200) {
        left: -7.25%;
      }
    }
  }

  .current .content_block {
    &::after {
      @include min-screen(1200) {
        left: -7.35%;
      }
    }
  }
}
