.banners {
  .banner_block {
    box-sizing: border-box;

    .inner {
      position: relative;
      margin: 0 0.5em;
      padding: 1em;
      border: 1px solid $separator;
      min-height: 260px;
      text-decoration: none;
      transition: 0.3s;

      @media (width >= 740px) {
        min-height: 480px;
      }
    }

    .vertically_centered {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .budget_receipt {
      h3 {
        font-size: 1.5em;
      }

      p {
        color: $color_text;
        font-size: 1.25em;
      }

      img {
        margin: 2em auto;

        @media (width >= 740px) {
          max-width: 560px;
        }
      }

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
      }
    }

    .budget_expense {
      h3 {
        margin-top: 0.5rem;
        font-size: 1.5em;
      }

      .circle_figure {
        height: 100%;
        position: absolute;
        top: 45%;
        width: calc(100% - 2em);
        text-align: center;

        @media (width >= 740px) {
          top: 40%;
        }

        div {
          font-size: 3em;
          border-radius: 50%;
          border: 1px solid #ddd;
          height: 3em;
          margin: 0 auto;
          width: 3em;
          line-height: 3em;
          color: var(--color-base);

          @media (width >= 740px) {
            font-size: 4em;
          }
        }
      }
    }
  }

  .banner_block:first-child .inner {
    margin-left: 0;
  }

  .banner_block:last-child .inner {
    margin-right: 0;
  }

  @media (width <= 740px) {
    .banner_block .inner {
      margin: 0 0 1em;
    }
  }
}
