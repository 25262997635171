.treemap_node {
  font-size: 0.8em;
  line-height: 1.2em;
  overflow: hidden;
  position: absolute;
  color: white;
  padding: 0;
}

.treemap_node.clickable {
  cursor: pointer;
}

.treemap_node p {
  margin: 0;
  padding: 8px 8px 0;
}
