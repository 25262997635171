/*
 * Horizontal menu
 * Extended from pure-menu
 *
 */
.pure-menu-horizontal--custom {
  &.pure-menu.pure-menu-horizontal {
    margin-bottom: 50px;

    li {
      border: 1px solid rgba(var(--color-base-string), 0.15);
      text-transform: uppercase;
      font-weight: 200;

      a {
        text-decoration: none;
      }

      &.active {
        font-weight: 400;
        background: rgba(var(--color-base-string), 0.15);
        border: 0;

        &:hover::after {
          border-top-color: #eee;
        }

        &::after {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          position: absolute;
          border-top-color: rgba(var(--color-base-string), 0.15);
          border-width: 15px;
          margin-left: -15px;
        }
      }
    }
  }
}
