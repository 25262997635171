.timeline {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 3rem;

  @include screen(768) {
    margin-bottom: 0;
  }

  .ticks {
    font-size: 1rem;
    fill: $color_separator;
    font-weight: 300;

    text {
      cursor: pointer;
    }

    & text.active {
      font-weight: 600;
      fill: $color_headers;
    }

    @media (width >= 740px) {
      font-size: 1.25rem;
    }
  }

  .year-circles {
    fill: $color_separator;
    stroke: white;

    circle {
      cursor: pointer;
    }
  }

  .track {
    stroke: $color_secondary;
    stroke-width: 10px;
  }

  .track-inset {
    stroke: $color_secondary;
    stroke-width: 8px;
  }

  .track-overlay {
    pointer-events: stroke;
    stroke-width: 65px;
    stroke: transparent;
  }

  .handle {
    fill: var(--color-base);
    stroke: white;
    stroke-opacity: 0.5;
    stroke-width: 1.25px;
    cursor: pointer;
  }

  .proposal {
    fill: $color_proposal_bg;

    text {
      fill: $color_proposal;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
