/* visualizations */
$color_vis_place: #e9ad00;
$color_vis_province: #a479f4;
$color_vis_com: #aac44b;
$color_vis_country: #008998;

#vis_lineas_j {
  width: 70%;
  float: left;
  background-color: #fff;
}

#density_vis_per {
  width: 50%;
  overflow: hidden;
}

.axis path {
  fill: none;
  stroke-width: 1px;
}

.axis line {
  stroke-width: 1px;
}

.evolution_line {
  fill: none;
}

/* Tooltip */

.vis_lineas_j_tooltip {
  top: 0;
  right: 0;
  position: absolute;
  padding: 5px;
  line-height: 140%;
  width: 180px;
  height: 70px;
  font-size: 12px;
  background: #faf7f5;
  border: 1px solid #424242;
  border-radius: 5px;
  pointer-events: none;
  text-align: right;
}

/* Legends */

.le {
  display: inline-block;

  @include border-radius(10px);

  height: 10px;
  width: 10px;
  margin: 0 10px 0 0;
}

.le-place {
  background: $color_vis_place;
}

.le-province {
  background: $color_vis_province;
}

.le-com {
  background: $color_vis_com;
}

.le-country {
  background: $color_vis_country;
}

.line-txt-group {
  font-size: 1rem;
  font-weight: 600;
  fill: #4a4a4a;
}

.line-txt-detail {
  font-size: .875rem;
  font-weight: 400;
  fill: #767168;
}
