.gradient-bg {
  background-image: linear-gradient(-180deg, #ecfafc 0%, white 50%);
  margin: 0 -1em;
  margin-bottom: 5em;
  padding: 1em;

  @media (width >= 768px) {
    margin: 0;
    margin-bottom: 5em;
  }
}

.processes_home .news_teaser {
  @include min-screen(740) {
    margin-top: -1.75em;
  }
}

.news_teaser,
.activities_teaser {
  margin: 0 0 4em;

  h3 {
    line-height: 1.3;
    font-size: 1.25em;
    margin-top: 0;
    margin-bottom: 0.25em;
  }

  p {
    margin-top: 0.25em;
  }
}

.news_teaser,
.news_article,
.activities_teaser {
  .meta {
    font-size: $f7;
    opacity: 0.5;
  }
}

.content {
  // article:not(.news_article)
  .inner {
    max-width: 650px;
    margin: 0 auto;
    text-align: left;

    @media (width >= 768px) {
      padding: 0;
      padding-top: 5em;
    }

    h1 {
      font-weight: 600;
      font-size: 2.25em;
      margin-bottom: 0.25em;
    }

    p {
      font-weight: 500;
      font-size: 1.125em;
      margin-top: 0;
      line-height: 1.3;
    }

  }

  article {
    & > img {
      text-align: left;
      margin: 1em auto;
      max-width: 100%;
    }

    p {
      font-size: 1.125em;
      line-height: 1.5;
    }

    ul {
      padding-left: 2em;
      margin-bottom: 3em;
      font-size: 1.125rem;
    }

    ul li {
      margin-bottom: 0.25em;
      list-style-type: disc;
    }

    h2 {
      font-size: $f4;
      padding-bottom: 0.5em;
      border-bottom: 1px solid #deeff1;
      margin-top: 2em;
    }

    h3 {
      font-size: $f5;
      margin-top: 2em;

      small {
        @include circle(18px);
      }
    }

    h4 {
      font-size: $f6;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 2em;
    }

    h5 {
      font-size: 1em;
    }

    .number {
      padding-top: 0.5em;
      padding-bottom: 2em;

      p {
        margin-top: 0;
        color: $text_soft;
        font-size: 0.775em;
      }

      div {
        color: var(--color-base);
        font-weight: 300;
        font-size: 3em;
      }
    }

    blockquote {
      margin: 0;
      position: relative;

      p {
        margin-top: 0;
        font-weight: 300;
        font-size: 1.5em;
        line-height: 1.25;

        @media (width >= 768px) {
          font-size: 2.25em;
        }
      }

      .share {
        position: absolute;
        right: 0;

        a {
          color: $color_headers;

          &:not(:last-child) {
            margin-right: 0.25em;
          }
        }
      }
    }

    figure {
      position: relative;
      max-width: 1024px;
      margin: 0;

      @media (width >= 768px) {
        margin-bottom: 3em;
        min-width: 120%;
        margin-left: -10%;
      }

      figcaption {
        color: $text_soft;
        font-size: 0.775em;
        line-height: 1.4;
        margin-top: 0.5em;

        @media (width >= 768px) {
          display: inline-block;
          position: absolute;
          margin-left: 0.25em;
          right: 0;
        }
      }
    }

    dl {
      box-sizing: border-box;
      background: rgba(var(--color-base-string), 0.03);
      max-width: 730px;
      padding: 1em;
      display: flex;
      flex-wrap: wrap;
      margin: 1em -1em;

      @media (width >= 768px) {
        padding: 30px 65px;
        margin: 1em auto;
        margin-bottom: 4em;
        min-width: 120%;
        margin-left: -10%;
      }

      & > div {
        &:not(:last-child) {
          margin-bottom: 1.5em;
        }

        dt {
          color: var(--color-base);
          font-weight: 600;
          margin: 0;
        }

        dd {
          font-size: 1em;
          line-height: 1.4;
          margin: 0;
        }

        @media (width >= 768px) {
          width: 48%;

          &:nth-child(odd) {
            margin-right: 1em;
          }
        }
      }
    }

    .note {
      position: relative;
      padding-top: 0.5em;
      margin-bottom: 4em;
      font-size: 0.775em;
      color: $text_soft;
    }

    .sources {
      margin-top: 8em;

      h4 {
        padding-bottom: 0.5em;
        border-bottom: 1px solid #deeff1;
      }

      ul {
        list-style: none;
        padding-left: 0;

        li {
          margin-bottom: 1em;
        }
      }
    }
  }

  ol {
    counter-reset: list;
    list-style: none;
    font-size: 1.125em;
    padding-left: 0;

    li {
      counter-increment: list;
      margin-bottom: 1em;
      line-height: 1.5;
    }

    li::before {
      @include circle(18px);

      content: counter(list);
      margin-right: 8px;

      @media (width >= 768px) {
        margin-left: -29px;
      }
    }
  }
}

.content_side_nav_location {
  font-size: $f7;
}

.list_items_from_collection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 25px;

  @include screen(640, 1024) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include screen(639) {
    display: block;

    a {
      display: block;
    }
  }

  a {
    text-decoration: none;
    color: $color_text;
    border: 1px solid $color_main_soft;
    padding: 1em;
    transition: box-shadow 0.5s ease;
  }

  a:hover {
    text-decoration: none;
    color: $color_text;

    // background: $color_neutral_soft;
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
  }

  .collection_item {
    img {
      width: 100%;
      height: auto;
    }

    span.date {
      font-size: $f7;
      opacity: .65;
    }

    h2 {
      font-size: $f5;
      margin: 0 0 .5rem;
      color: var(--color-base);
    }

    p.description {
      color: $color_text;
      font-size: $f7;
      margin: 0;
      line-height: 1.4;
    }
  }
}

.card-container {
  background: rgba(var(--color-base-string), 0.06);
  display: flex;

  .card-inner-side {
    @include screen(740) {
      width: 25%;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}
