/* Widget */
.card_container {
  position: relative;
  perspective: 1000px;
  perspective: 1000px;
  perspective: 1000px;
  perspective: 1000px;
  min-height: 180px;
  border-radius: 8px;

  @include screen(768) {
    margin-bottom: 1em;
    min-height: 220px;
  }

  .indicator_widget {
    transition: 0.6s;
    transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
  }

  .front,
  .back {
    border-radius: 8px;
    background: #f0f9f9;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
    backface-visibility: hidden;
    backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s all;
  }

  .front:hover,
  .front:active,
  .front:focus,
  .back:hover,
  .back:active,
  .back:focus {
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
  }

  .widget_headline {
    font-weight: 600;
    padding: 1rem;
  }

  .widget_body {
    min-height: 80px;
    line-height: 80px;
    padding: 0 1rem;
    position: relative;

    .half {
      width: 48%;
      display: inline-block;
    }

    .category {
      color: $text_soft;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      font-size: 0.85rem;
    }

    .sparkline {
      position: absolute;
      top: 0;
      line-height: 0;
      width: calc(100% - 2rem);

      path {
        fill: none;
        stroke-linejoin: round;
        stroke-linecap: round;
        stroke-opacity: 0.15;
        stroke-width: 4;
      }

      circle {
        fill-opacity: 0.15;
      }
    }

    .widget_table {
      .sparktable {
        width: 25%;

        path {
          fill: none;
          stroke-linejoin: round;
          stroke-linecap: round;
          stroke-width: 1.5;
        }
      }
    }

    ol {
      padding: 0 1rem;
    }

    .bars {
      .row {
        line-height: normal;
        border-bottom: 1px solid rgb(0 0 0 / 20%);
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }

        .key {
          padding-right: 5px;
          width: 30%;
          vertical-align: top;
          display: inline-block;
          font-size: 0.85em;
        }

        .bar {
          display: inline-block;
          height: 10px;
          background: var(--color-base);

          @media (width >= 768px) {
            height: 20px;
          }
        }

        .qty {
          font-size: 90%;
          vertical-align: top;
          padding-left: 0.5rem;
          font-weight: 300;
          display: inline-block;
        }
      }
    }
  }

  .widget_body.widget_comparison {
    line-height: 37px;

    .first {
      border-bottom: 1px solid rgb(0 0 0 / 20%);
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .first,
    .second {
      span:not(.figure) {
        font-size: 1rem;
        color: $color_text;
      }
    }
  }

  .widget_body.categories {
    line-height: inherit;
    font-size: 0.85rem;
  }

  .widget_headline i {
    color: #b1b8b8;
    margin: 0.25em 0.25em 0 0;
    transition: all 0.25s;
    cursor: pointer;
  }

  .indicator_widget:focus .widget_headline i,
  .indicator_widget:active .widget_headline i,
  .indicator_widget:hover .widget_headline i, {
    color: $color_text;
  }

  .widget_headline .fa-times {
    color: $color_text;
  }

  .back .fa-facebook,
  .back .fa-twitter {
    color: $color_text;
  }

  .front .fa-facebook,
  .front .fa-twitter {
    opacity: 0;
    transition: 0.25s;
  }

  .front:hover .fa-facebook,
  .front:hover .fa-twitter {
    opacity: 1;
  }

  .widget_figure {
    font-weight: 300;
    font-size: 1.75rem;
    color: var(--color-base);
  }

  .widget_pct {
    font-weight: 300;
  }

  .widget_pct i {
    margin-left: 0.25rem;
  }

  .widget_pct .fa-caret-up {
    color: $color_success;
  }

  .widget_pct .fa-caret-down {
    color: $color_warn;
  }

  // Widget additional info screen
  .widget_info {
    min-height: 80px;
    padding: 0 1rem;
  }

  // Widget metadata
  .widget_meta {
    padding: 1rem;

    .fas {
      font-size: 70%;
      color: #9c9c9c;
    }

    .widget_src {
      vertical-align: middle;
      display: inline-block;
      font-size: 70%;
      width: 20%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #7f7f7f;
      font-weight: 300;

      a {
        font-weight: 300;
        color: #7f7f7f;
        text-decoration: none;
        cursor: pointer;

        &:hover{
          text-decoration: underline;
          text-decoration-color: #7f7f7f;
        }
      }
    }

    small,
    .widget-sep {
      font-weight: 300;
      color: #7f7f7f;
      font-size: 70%;
    }

    .widget-sep {
      margin: 0 0.15rem;
    }
  }

  /* Card flip */
  &.hover {
    .indicator_widget {
      transform: rotateY(180deg);
      transform: rotateY(180deg);
      transform: rotateY(180deg);
      transform: rotateY(180deg);
    }

    .front,
    .back {
      box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    }
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
    transform: rotateY(0deg);
    transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);
    transform: rotateY(180deg);
    transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .figure-row {
    padding-bottom: 0.25rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
  }
}

@media (width >= 768px) {
  .card_container {
    min-height: 260px;
  }

  .widget_body {
    min-height: 100px;
    line-height: 100px;
  }

  .widget_figure {
    font-size: 2.5rem;
  }

  .widget_info {
    min-height: 100px;
  }
}
