.tipsy {
  z-index: 100000000;
  position: absolute;
  padding: 5px;
  font-size: 14px;
  line-height: 21px;
}

.tipsy-inner {
  max-width: 300px;
  padding: 10px 12px;
  color: white;
  background-color : black;
  text-align: center;
  border-radius: 0;
  border-radius : 0;
  border-radius: 0;
}

/* Arrow pseudo-border */
.tipsy-arrow::before {
  // border-radius: 2px;
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #979797;
  content: '';
}

/* Arrow fill */
.tipsy-arrow::after {
  // border-radius: 2px;
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent white;
  content: '';
}

.tipsy-n .tipsy-arrow::before {
  top: -8px;
  left: 50%;
}

.tipsy-n .tipsy-arrow::after {
  top: -6px;
  left: 50%;
}

.tipsy-nw .tipsy-arrow::before {
  top: -8px;
  left: 10%;
}

.tipsy-nw .tipsy-arrow::after {
  top: -6px;
  left: 10%;
}

.tipsy-ne .tipsy-arrow::before {
  top: -8px;
  right: 5%;
}

.tipsy-ne .tipsy-arrow::after {
  top: -6px;
  right: 6%;
}

.tipsy-s .tipsy-arrow::before {
  transform: rotate(180deg);
  bottom: -8.25px;
  left: 50%;
}

.tipsy-s .tipsy-arrow::after {
  transform: rotate(180deg);
  bottom: -6px;
  left: 50%;
}

.tipsy-sw .tipsy-arrow::before {
  transform: rotate(180deg);
  bottom: -8px;
  left: 9%;
}

.tipsy-sw .tipsy-arrow::after {
  transform: rotate(180deg);
  bottom: -5px;
  left: 9%;
}

.tipsy-se .tipsy-arrow::before {
  transform: rotate(180deg);
  bottom: -8px;
  right: 5.25%;
}

.tipsy-se .tipsy-arrow::after {
  transform: rotate(180deg);
  bottom: -5px;
  right: 6%;
}

.tipsy-e .tipsy-arrow::before {
  transform: rotate(90deg);
  top: 49%;
  right: -6%;
}

.tipsy-e .tipsy-arrow::after {
  transform: rotate(90deg);
  top: 50%;
  right: -4%;
}

.tipsy-w .tipsy-arrow::before {
  transform: rotate(-90deg);
  top: 49%;
  left: -1%;
}

.tipsy-w .tipsy-arrow::after {
  transform: rotate(-90deg);
  top: 50%;
  left: 0%;
}
