.receipt {
  background: #e5f3f5;
  border-radius: 4px;

  .padding {
    padding: 1em;

    @include screen(740, 1024) {
      padding: 3em 2em 1em;
    }

    @include min-screen(1025) {
      padding: 3em 3em 1em;
    }
  }

  .pure-u-1 {
    padding: 0.5em;
    box-sizing: border-box;
  }

  .receipt_item {
    font-size: 1.25em;
    background: #f2f9fa;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .receipt_dropdown {
    padding: 0.5em 0;
    position: relative;
    display: inline-block;
    color: var(--color-base);

    @include min-screen(740) {
      padding: 0 0.5em;
    }

    select {
      color: $color_text;
      display: inline-block;
      height: 44px;
      width: 100%;
      margin: 0;
      padding: 0.5em 2.25em 0.5em 1em;
      line-height: 1.5;
      background-color: white;
      border: 0;
      border: 1px solid #aeaeae;
      border-radius: 4px;
      cursor: pointer;
      outline: 0;
      appearance: none;
      appearance: none;
      appearance: none;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 1.25rem;
      display: inline-block;
      content: "";
      width: 0;
      height: 0;
      margin-top: -0.15em;
      pointer-events: none;
      border-top: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-bottom: 0.5em solid transparent;
      border-left: 0.5em solid transparent;
    }
  }

  .receipt_separator {
    display: none;

    @include min-screen(740) {
      display: block;
      background-image: linear-gradient(to right, var(--color-base) 33%, rgb(255 255 255 / 0%) 0%);
      background-position: bottom;
      background-size: 7px 2px;
      background-repeat: repeat-x;
    }
  }

  .receipt_calc {
    display: flex;
    padding: 0.5em 0;

    @include flexbox(1em, column);

    @include min-screen(740) {
      padding: 0 0.5em;

      @include flexbox(1em, row);
    }

    .bg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f2f9fa;
      padding: 0 0.5em;
      border-radius: 4px;

      @include min-screen(740) {
        width: 100%;
      }

      > small:first-child {
        display: inline-block;

        @include min-screen(740) {
          display: none;
        }
      }

      &:not(:last-child) {
        opacity: 0.5;
      }

      span,
      input {
        font-size: 1.15em;
      }

      .toggle-clickable {
        width: 100%;
        cursor: pointer;
        text-align: right;
      }

      small {
        color: $color_meta_text;
        font-size: 90%;
      }

      input {
        height: 100%;
        width: calc(100% + 1em);
        margin: 0 -.5em;
        border: 0;
        background: transparent;

        &:focus {
          background: $color_main_negative;
        }
      }

      &.bg--transparent {
        background: transparent;
        justify-content: center;
      }
    }
  }

  .receipt_result {
    border-top: 1px solid #d7e2e4;
    padding-top: 2em;
    padding-bottom: 2em;

    h3 {
      margin: 0;
      font-size: 1.45em;
      color: $color_headers;
    }

    .receipt_result_number {
      margin-top: 0.5em;
      font-size: 2em;

      @include min-screen(740) {
        margin-top: 0;
        text-align: right;
      }

      // font-weight: 600;
    }
  }
}

.receipt_bill {
  background: #e5f3f5;
  border-radius: 4px;
  padding: 1em;

  @include min-screen(740) {
    padding: 3em;
  }

  .bill_row {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid #d7e2e4;
    padding: 0.5em 0;
    cursor: pointer;

    &:hover > .line,
    &:focus > .line,
    &:active > .line {
      text-decoration: underline;
    }

    &.toggled > .line {
      text-decoration: underline;
    }

    .toggle {
      left: -1.5em;
      top: 0.85em;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      background: var(--color-base);
      color: white;
      line-height: 13px;
      text-align: center;
      cursor: pointer;
      margin-top: 0.35em;

      @include min-screen(740) {
        position: absolute;
        margin-top: 0;
      }

      .fas {
        font-size: 10px;
        margin: 0;
      }
    }

    .line {
      font-weight: 600;
      font-size: 1.15em;
      flex-basis: 70%;
    }

    .figure {
      opacity: 0.7;
      font-size: 1.15em;
    }

    .children {
      flex-basis: 100%;
      margin-top: 0.5em;
      cursor: auto;

      .bill_row_child {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.75em;

        @include min-screen(740) {
          margin-bottom: 0.25em;
        }

        .line {
          font-weight: 400;
          text-transform: uppercase;
          font-size: 1em;
          opacity: 0.5;
        }

        .figure {
          font-size: 1em;
        }
      }
    }
  }
}
